var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "70%",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "2vh",
            title: "Danh sách người nhận " + this.status,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "receiverList",
              attrs: {
                "label-width": "140px",
                model: _vm.receiverList,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "div",
                { staticClass: "table-content row-data" },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        "highlight-current-row": "",
                        data: _vm.receiverList,
                        "header-cell-style": _vm.tableHeaderColor,
                        border: "",
                        "max-height": 500,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fullName",
                          label: "Họ tên",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "phone",
                          label: "Số điện thoại",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "appType",
                          label: "Đối tượng nhận",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "sendContent",
                          label: "Nội dung",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Tác vụ",
                          align: "center",
                          width: "160",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.sendDel
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "warning",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRevoke(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Thu hồi")]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "success",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRevoke(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Hiện lại")]
                                    ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelete(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Xóa")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "tab-infor-button" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.handleMultiDelete()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-delete" }),
                  _c("span", [_vm._v("Xóa")]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleMultiRevoke()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Thu hồi")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }